'use client';

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { NotificationContainer } from '@/components/Notifications/NotificationContainer';
import { getNotifications } from '@/services/getNotifications';
import Notifications from './Notifications';

const NotificationLoader: React.FC = () => {
    const { data: notifications } = useQuery({
        queryKey: ['site-notifications'],
        queryFn: () => getNotifications(),
    });
    if (!notifications || notifications.length === 0) {
        return null;
    }
    return (
        <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            dense={true}
            Components={{
                default: NotificationContainer,
            }}
        >
            <Notifications notifications={notifications} />
        </SnackbarProvider>
    );
};

export default NotificationLoader;
