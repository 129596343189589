'use client';

import React from 'react';
import { JucyUiProvider } from '@jucy-ui/components';
import { CmsSiteConfig } from '@/services/cms/models/CmsSiteConfig';
import { SiteProviderContext } from './contexts/SiteProviderContext';

export interface SiteLocaleProviderProps {
    site: CmsSiteConfig;
    locale?: string;
    children?: React.ReactNode;
}

export const SiteLocaleProvider: React.FC<SiteLocaleProviderProps> = ({ site, locale, children }) => (
    <SiteProviderContext.Provider value={{ site }}>
        <JucyUiProvider brand="jucy" locale={locale}>
            {children}
        </JucyUiProvider>
    </SiteProviderContext.Provider>
);
