import React from 'react';
import Box from '@mui/material/Box';
import { MaterialDesignContent, SnackbarContent } from 'notistack';

export const NotificationContainer = React.forwardRef<HTMLDivElement, React.ComponentProps<typeof MaterialDesignContent>>(({ ...props }, ref) => {
    const { id, message, action: componentOrFunctionAction, iconVariant, variant, hideIconVariant, style } = props;
    const icon = iconVariant[variant];

    let action = componentOrFunctionAction;
    if (typeof action === 'function') {
        action = action(id);
    }
    return (
        <Box
            component={SnackbarContent}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mb: 0.5,
                width: '100%',
            }}
            role="alert"
            aria-describedby={'notistack-snackbar'}
            style={style}
            ref={ref}
        >
            <div id={'notistack-snackbar'}>
                {!hideIconVariant ? icon : null}
                {message}
            </div>
            {action && <div>{action}</div>}
        </Box>
    );
});
NotificationContainer.displayName = 'NotificationContainer';
