import { cookies } from '@/lib/jucyCookies';

export default class NotificationUtils {
    static getSeenIds(): number[] {
        const value = cookies.get<number[]>('seen-notifications');
        return value || [];
    }

    static setSeenIds(ids: number[]): void {
        cookies.set('seen-notifications', Array.from(new Set(ids)));
    }

    static addSeenId(id: number): void {
        NotificationUtils.setSeenIds([...NotificationUtils.getSeenIds(), id]);
    }

    static hasSeen(id: number): boolean {
        return NotificationUtils.getSeenIds().includes(id);
    }
}
