'use client'
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { getQueryClient } from '@/lib/getQueryClient';

export interface ReactQueryProviderProps {
    children?: React.ReactNode;
}

export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({ children }) => (
    <QueryClientProvider client={getQueryClient()}>
        {children}
    </QueryClientProvider>
);
