import { z } from 'zod';

export const cmsLinkSchema = z.object({
    id: z.number(),
    title: z.string().optional(),
    openInNew: z.boolean(),
    url: z.string(),
    type: z.string(),
});

export type CmsLink = z.infer<typeof cmsLinkSchema>;
