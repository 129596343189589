import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AlertProps, alertClasses, buttonClasses } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { mergeSx } from '@jucy-ui/appearance';
import { CmsHtmlContent } from '@/components/CmsHtmlContent';
import { useSiteLinkPrefix } from '@/hooks/useSiteLinkPrefix';
import { CmsNotification } from '@/services/cms/models/CmsNotification';

interface NotificationProps extends Omit<AlertProps, 'onClick'> {
    notification: CmsNotification;
    onClick?: () => void;
}

const Notification: React.FC<NotificationProps> = ({ notification, className, sx, onClick, ...props }) => {
    const addSiteLinkPrefix = useSiteLinkPrefix();
    return (
        <MuiAlert
            elevation={6}
            variant="filled"
            severity="warning"
            sx={mergeSx(
                (theme) => ({
                    background: theme.palette.primary.dark,
                    [`& .${alertClasses.message}`]: {
                        width: '100%',
                        overflow: 'visible',
                    },
                    '& p:last-child': {
                        marginBottom: 0,
                    },
                    [`& a:not(.${buttonClasses.root})`]: {
                        color: theme.palette.primary.contrastText,
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        transition: theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.short,
                        }),
                        '&:hover': {
                            opacity: 0.8,
                            color: theme.palette.primary.contrastText,
                        },
                    },
                }),
                sx
            )}
            {...props}
        >
            <IconButton
                aria-label="close"
                size="small"
                onClick={onClick}
                sx={(theme) => ({
                    position: 'absolute',
                    color: theme.palette.primary.contrastText,
                    top: theme.spacing(1),
                    right: theme.spacing(1),
                })}
            >
                <CloseIcon />
            </IconButton>
            {notification.showTitle && notification.title ? <AlertTitle sx={{ fontSize: '1rem' }}>{notification.title}</AlertTitle> : null}
            {notification.content ? <CmsHtmlContent html={notification.content} plain={true} /> : null}
            {notification.readMoreLink?.url ? (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        sx={(theme) => ({
                            px: 1,
                            mr: -1,
                            textDecoration: 'none',
                            color: theme.palette.primary.contrastText,
                            '&:hover': {
                                color: theme.palette.primary.contrastText,
                            },
                        })}
                        href={addSiteLinkPrefix(notification.readMoreLink.url)}
                        onClick={() => {
                            onClick?.();
                        }}
                        target={notification.readMoreLink.openInNew ? '_blank' : undefined}
                        size="small"
                        variant="outlined"
                    >
                        {notification.readMoreLink.title ? notification.readMoreLink.title : 'Read more'}
                    </Button>
                </Box>
            ) : null}
        </MuiAlert>
    );
};

export default Notification;
