import { z } from 'zod';
import { cmsLinkSchema } from '@/services/cms/models/CmsLink';

export const cmsNotificationSchema = z.object({
    id: z.number(),
    title: z.string().optional(),
    showTitle: z.boolean().optional(),
    content: z.string().optional(),
    readMoreLink: cmsLinkSchema.optional(),
    showOn: z.string().array().optional(),
});

export type CmsNotification = z.infer<typeof cmsNotificationSchema>;
