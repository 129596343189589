'use client';

import React, { useCallback, useEffect } from 'react';
import { AlertProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { CmsNotification } from '@/services/cms/models/CmsNotification';
import Notification from './Notification';
import NotificationUtils from './NotificationUtils';

interface NotificationsProps extends AlertProps {
    notifications: CmsNotification[];
}

const Notifications: React.FC<NotificationsProps> = ({ notifications }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const onClose = useCallback(
        (id: number) => {
            NotificationUtils.addSeenId(id);
            closeSnackbar(`notification-${id}`);
        },
        [closeSnackbar]
    );
    useEffect(() => {
        notifications
            .filter((n) => !NotificationUtils.hasSeen(n.id))
            .forEach((notification) => {
                enqueueSnackbar(<Notification key={`notification-${notification.id}`} notification={notification} onClick={() => onClose(notification.id)} />, {
                    key: `notification-${notification.id}`,
                    persist: true,
                    preventDuplicate: true,
                });
            });
    }, [enqueueSnackbar, notifications, onClose]);
    return null;
};

export default Notifications;
