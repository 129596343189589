import { ZodError, ZodIssue } from 'zod';

export const createSchemaError = <Data>(issues: ZodIssue[], data?: Data) => {
    const error = new SchemaError<Data>(issues);
    error.data = data;
    return error;
};

export class SchemaError<Data> extends ZodError {
    data?: Data;
}
