'use client';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { env } from '@/env';

export const DevDebug = () => {
    if (env.NEXT_PUBLIC_ENV !== 'dev') {
        return null;
    }
    return (
        <Card sx={{ p: 1, position: 'fixed', bottom: 0, left: 0, zIndex: 1 }} elevation={11}>
            <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none', xl: 'none' } }}>isXs</Box>
            <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>isSm</Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'none', xl: 'none' } }}>isMd</Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'none' } }}>isLg</Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block' } }}>isXl</Box>
        </Card>
    );
};
